/**
 * Components
 *
 * TABLE OF CONTENTS
 * 1. Components
 * 	  1.1 Article
 * 	  1.2 Author
 * 	  1.3 Avatar Item
 * 	  1.4 Browser
 * 	  1.5 Chat
 * 	  1.6 Chocolat
 * 	  1.7 Custom Tab
 * 	  1.8 DataTables
 * 	  1.9 Date Range Picker
 * 	  1.10 Dropzone
 * 	  1.11 Flag Icon
 * 	  1.12 Full Calendar
 * 	  1.13 Gallery
 * 	  1.14 Image Preview
 * 	  1.16 jQVmap
 * 	  1.17 Profile
 * 	  1.18 Select2
 * 	  1.19 Selectric
 * 	  1.20 Slider
 * 	  1.21 Sparkline
 * 	  1.22 Statistics
 * 	  1.23 Summary
 * 	  1.24 Summernote
 * 	  1.25 Sweet Alert
 * 	  1.26 Tags Input
 * 	  1.27 Time Picker
 * 	  1.28 Toast
 * 	  1.29 User Item
 * 	  1.30 Weather
 * 	  1.31 Weather Icon
 * 	  1.32 PWStrength
 * 	  1.33 Product
 * 	  1.34 Ticket
 * 	  1.35 Owl Carousel
 * 	  1.36 Activities
 * 	  1.37 Invoices
 * 	  1.38 Empty States
 *    1.39 Pricing
 *    1.40 Hero
 *    1.41 Avatar
 *    1.42 Wizard
 *    1.43 Form Wizard
 *    1.44 Chart
 *    1.45 Email
 *    1.46 Portfolio
 *    1.47 Widgets
 *    1.48 Calendar
 *    1.49 Setting Sidebar
 *    1.50 TODO
 */

@import "_variable";
@import "_mixin";

/* 1.1 Article */
@import "components/_article";
/* 1.2 Author */
@import "components/_author";
/* 1.3 Avatar Item */
@import "components/_avataritem";
/* 1.4 Browser */
@import "components/_browser";
/* 1.5 Chat */
@import "components/_chat";
/* 1.6 Chocolat */
@import "components/_chocolat";
/* 1.7 Custom Tab */
@import "components/_customtab";
/* 1.8 DataTables */
@import "components/_datatables";
/* 1.9 Date Range Picker */
@import "components/_daterangepicker";
/* 1.10 Dropzone */
@import "components/_dropzone";
/* 1.11 Flag Icon */
@import "components/_flagicon";
/* 1.12 Full Calendar */
@import "components/_fullcalendar";
/* 1.13 Gallery */
@import "components/_gallery";
/* 1.14 Image Preview */
@import "components/_imagepreview";
/* 1.16 jQVmap */
@import "components/_jqvmap";
/* 1.17 Profile */
@import "components/_profile";
/* 1.18 Select2 */
@import "components/_select2";
/* 1.19 Selectric */
@import "components/_selectric";
/* 1.20 Slider */
@import "components/_slider";
/* 1.21 Sparkline */
@import "components/_sparkline";
/* 1.22 Statistics */
@import "components/_statistics";
/* 1.23 Summary */
@import "components/_summary";
/* 1.24 Summernote */
@import "components/_summernote";
/* 1.25 Sweet Alert */
@import "components/_sweetalert";
/* 1.26 Tags Input */
@import "components/_tagsinput";
/* 1.27 Time Picker */
@import "components/_timepicker";
/* 1.28 Toast */
@import "components/_toast";
/* 1.29 User Item */
@import "components/_useritem";
/* 1.30 Weather */
@import "components/_weather";
/* 1.31 Weather Icon */
@import "components/_weathericon";
/* 1.32 PWStrength */
@import "components/_pwstrength";
/* 1.33 Product */
@import "components/_product";
/* 1.34 Ticket */
@import "components/_ticket";
/* 1.35 Owl Carousel */
@import "components/_owlcarousel";
/* 1.36 Activities */
@import "components/_activities";
/* 1.37 Activities */
@import "components/_invoice";
/* 1.38 Empty States */
@import "components/_empty_state";
/* 1.39 Pricing */
@import "components/_pricing";
/* 1.40 Hero */
@import "components/_hero";
/* 1.41 Avatar */
@import "components/_avatar";
/* 1.42 Wizard */
@import "components/_wizard";
/* 1.43 Form Wizard */
@import "components/_formwizard";
/* 1.44 Chart */
@import "components/_chart";
/* 1.45 Email */
@import "components/_email";
/* 1.46 Portfolio */
@import "components/_portfolio";
/* 1.47 Widget */
@import "components/_widget";
/* 1.48 Calendar */
@import "components/_calendar";
/* 1.49 Setting Sidebar */
@import "components/_settingSidebar";
/* 1.50 TODO */
@import "components/_todo";
/* 1.50 checkbox */
@import "components/_checkbox";