.empty-state {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;

  .empty-state-icon {
    position: relative;
    background-color: color(primary);
    width: 80px;
    height: 80px;
    line-height: 100px;
    border-radius: 5px;

    i {
      font-size: 40px;
      color: #fff;
      position: relative;
      z-index: 1;
    }
  }

  h2 {
    font-size: 20px;
    margin-top: 30px;
  }

  p {
    font-size: 16px;
  }
}