.browser {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-size: 100%;

  &.browser-chrome {
    background-image: url("../img/browsers/chrome.png");
  }

  &.browser-firefox {
    background-image: url("../img/browsers/firefox.png");
  }

  &.browser-internet-explorer {
    background-image: url("../img/browsers/internet-explorer.png");
  }

  &.browser-opera {
    background-image: url("../img/browsers/opera.png");
  }

  &.browser-safari {
    background-image: url("../img/browsers/safari.png");
  }
}