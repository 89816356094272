table.dataTable {
  border-collapse: collapse !important;

  th,
  td {
    vertical-align: middle;
  }

  thead {

    th,
    td {
      border-bottom: 1px solid #ddd !important;
    }
  }

  &.no-footer {
    border-bottom: 1px solid #ddd !important;
  }

  img {
    -webkit-box-shadow: 0 5px 15px 0 rgba(105, 103, 103, 0.5);
    box-shadow: 0 5px 15px 0 rgba(105, 103, 103, 0.5);
    border: 2px solid #ffffff;
    border-radius: 10px;
  }

  .badge-shadow {
    @include box-shadow(0, 0.5rem, 1rem, rgba(0, 0, 0, 0.15));
  }

  .tblHrdcheckbox {
    margin-left: 15px;
  }
}

.dataTables_wrapper {
  padding: 0 !important;
  font-size: 13px !important;

  .dataTables_paginate .paginate_button {
    padding: 0 !important;
    margin: 0 !important;
    float: left;
  }
}

div.dataTables_wrapper div.dataTables_processing {
  font-size: 0 !important;
  background-image: url("../img/spinner.svg") !important;
  background-color: #fff;
  background-size: 100%;
  width: 50px !important;
  height: 50px;
  border: none;
  @include shadow;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
  opacity: 1 !important;
}

.buttons-copy {
  background-color: #666 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 0 !important;
  width: 60px;
  margin: 0px 5px 0px 5px;
}

.buttons-excel {
  background-color: #59bf70 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 0 !important;
  width: 60px;
  margin: 0px 5px 0px 5px;
}

.buttons-csv {
  background-color: #2ab9d0 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 0 !important;
  width: 60px;
  margin: 0px 5px 0px 5px;
}

.buttons-pdf {
  background-color: #e91e63 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 0 !important;
  width: 60px;
  margin: 0px 5px 0px 5px;
}

.buttons-print {
  background-color: #6563ef !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 0 !important;
  width: 60px;
  margin: 0px 5px 0px 5px;
}