.avatar-item {
  position: relative;
  margin-bottom: 20px;

  img {
    border-radius: 50%;
  }

  .avatar-badge {
    position: absolute;
    bottom: -5px;
    right: 0;
    background-color: #fff;
    color: #000;
    @include shadow;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    width: 25px;
    height: 25px;
  }
}